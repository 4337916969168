import { useEffect, useState } from 'react';
import Announcement from '../components/Announcement';
import HeroMask from '../components/HeroMask';
import Layout from '../components/Layout';
import Section from '../components/Section';
import AllArticlesButton from '../components/cards/AllArticlesButton';
import ArticleCard from '../components/cards/ArticleCard';
import ArticleCardBig from '../components/cards/ArticleCardBig';
import CampaignCard from '../components/cards/CampaignCard';
import NavigationCard from '../components/cards/NavigationCard';
import { homePageQuery } from '../groq/pages/home';
import { sanityClient } from '../lib/sanity.server';
import {
  CampaignSingularType,
  DefaultSEOType,
  FrontPageType,
  ImageWithAltType,
  MenuType,
  NewCarEntriesType,
  SettingsType,
} from '../types';
import cn from '../utils/cn';
import fetchSanityData from '../utils/fetchSanityData';
import { websiteSchemaCode, organizationSchemaCode } from '../utils/SchemaCodes';
import SchemaRenderer from '../components/schemaRenderer';

interface PageProps {
  data: {
    content: FrontPageType & { defaultSEO: DefaultSEOType };
    menus: MenuType;
    settings: SettingsType;
  };
}

type CampaignsOverrideType = {
  _id: string;
  overline?: string;
  title: string;
  description: string;
  image: ImageWithAltType;
};

function Frontpage({ data }: PageProps) {
  const {
    menus,
    content: {
      seo,
      defaultSEO,
      overline,
      title,
      primaryAction,
      secondaryAction,
      image,
      topTasksTitle,
      topTasks,
      campaignsTitle,
      newCarEntries,
      servicesTitle,
      services,
      callToAction,
      articlesTitle,
      featureArticles,
      announcement,
    },
    settings,
  } = data;

  // State management for campaigns
  const [campaignData, setCampaignData] = useState({
    isTransverseActive: false,
    useNewCarCampaignCard: false,
    campaigns: [] as CampaignSingularType[],
    newCarCampaignsCard: undefined as CampaignsOverrideType | undefined,
  });

  const fetchCampaigns = async () => {
    const response = await fetchSanityData('frontPageCampaigns');
    setCampaignData(response);
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const { isTransverseActive, useNewCarCampaignCard, campaigns, newCarCampaignsCard } =
    campaignData;

  // Categorize campaigns
  const brandCampaigns = campaigns.filter((campaign) => campaign.brand);
  const remainingCampaigns = campaigns.filter((campaign) => !campaign.brand);

  const useNewCarEntry =
    useNewCarCampaignCard && newCarCampaignsCard && !isTransverseActive && !brandCampaigns.length;

  return (
    <Layout menus={menus} seo={seo} settings={settings} defaultSEO={defaultSEO}>
      <SchemaRenderer
        schemaMarkup={[websiteSchemaCode, organizationSchemaCode]}
        pageContent={data.content}
        alwaysShowTypes={['WebSite', 'Organization']}
      />
      <HeroMask
        overline={overline}
        title={title}
        image={image}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      />
      {announcement?.title && announcement.showOnTop && (
        <Section>
          <Announcement value={announcement} />
        </Section>
      )}
      {!!topTasks?.length && (
        <Section color="background">
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{topTasksTitle}</h2>
          <div className="grid gap-5 sm:grid-cols-6">
            {topTasks.map(
              (item, index) =>
                item.title &&
                item.href && (
                  <div
                    key={item.title}
                    className={cn(
                      index < 2 ? 'sm:col-span-3' : 'sm:col-span-2',
                      topTasks.length % 2 === 0 ? 'sm:col-span-3' : '',
                    )}
                  >
                    <NavigationCard link={item} />
                  </div>
                ),
            )}
          </div>
        </Section>
      )}
      {(!!brandCampaigns.length || !!remainingCampaigns.length) && (
        <Section>
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{campaignsTitle}</h2>
          <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-1">
            {useNewCarEntry ? (
              <CampaignCard
                key={newCarCampaignsCard._id}
                overline={newCarCampaignsCard.overline}
                title={newCarCampaignsCard.title}
                description={newCarCampaignsCard.description}
                href="/kampanjer"
                image={newCarCampaignsCard.image}
              />
            ) : (
              brandCampaigns.map(
                (campaign) =>
                  campaign.brand && (
                    <EntryByBrand
                      key={campaign.brand}
                      brand={campaign.brand}
                      newCarEntries={newCarEntries}
                    />
                  ),
              )
            )}
            {remainingCampaigns.map((campaign) => (
              <CampaignCard
                key={campaign._id}
                overline={campaign.overline}
                title={campaign.title}
                description={campaign.pitch}
                href={campaign.href || '/kampanjer'}
                image={campaign.image}
              />
            ))}
          </div>
        </Section>
      )}
      {services?.length > 0 && (
        <Section color="info">
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{servicesTitle}</h2>
          <div className="grid gap-5 lg:grid-cols-3">
            {services.map((service) => (
              <NavigationCard key={service.title} link={service} color="info" />
            ))}
          </div>
        </Section>
      )}
      {callToAction.isVisible && (
        <Section>
          <ArticleCardBig
            overline={callToAction.overline}
            title={callToAction.title}
            description={callToAction.description}
            href={callToAction.link.href}
            linkText={callToAction.link.linkText}
            image={callToAction.image}
          />
        </Section>
      )}
      {featureArticles.length > 0 && (
        <Section>
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{articlesTitle}</h2>
          <div className="mb-10 grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {featureArticles.map((article) => (
              <ArticleCard key={article.title} article={article} />
            ))}
          </div>
          <AllArticlesButton />
        </Section>
      )}
      {announcement?.title && !announcement.showOnTop && (
        <Section>
          <Announcement value={announcement} />
        </Section>
      )}
    </Layout>
  );
}

function EntryByBrand({
  brand,
  newCarEntries,
}: {
  brand: string;
  newCarEntries: NewCarEntriesType;
}) {
  const entry = newCarEntries[brand];
  if (!entry) return null;

  return (
    <CampaignCard
      key={entry.title}
      overline={entry.overline}
      title={entry.title}
      description={entry.description}
      image={entry.image}
      href={entry.externalLandingPage || `/kjope-bil/nybil/${brand}`}
    />
  );
}

export async function getStaticProps() {
  const data = await sanityClient.fetch(homePageQuery);

  if (!data.content) return { notFound: true };

  return {
    props: { data },
    revalidate: 1,
  };
}

export default Frontpage;
